








































import { Vue, Component } from 'vue-property-decorator'
import { FormSchema } from 'vue-form-generator'
import { toastMapper } from '@/store/modules/toast'
import UserFactory from '@/factories/user'
import UserAPI from '@/api/requests/user'
import { regFIO } from '@/config/default/regex'
import { minLengthPassword } from '@/config/default/user'
import { ContentAdapter } from '@/api/adapters/content'
import { phoneMask } from '@/config/default/masks'

const Mappers = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component
export default class Registration extends Mappers {
  private isValidForm = false
  private code = this.$route.query.activationCode

  $router: any
  private userInfo = UserFactory.emptyRegistrationUserInfo()

  private onValidated(isValid: boolean) {
    this.isValidForm = isValid
  }

  private onChangeInputs(newVal: any, schema: any) {
    this.$emit('model-updated', newVal, schema)
  }

  private updateValidateSchema() {
    ;(this.$refs.registerSchema as any).validate()
  }

  private schema: FormSchema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'text',
        model: 'surname',
        required: true,
        styleClasses: 'wm-100',
        label: 'Фамилия',
        placeholder: 'Фамилия',
        validator(value, field, model) {
          if (!regFIO.test(model.surname)) {
            return ['Проверьте правильность ввода фамилии']
          }
          return []
        }
      },
      {
        type: 'bootstrap',
        inputType: 'text',
        model: 'name',
        required: true,
        styleClasses: 'wm-100',
        label: 'Имя',
        placeholder: 'Имя',
        validator(value, field, model) {
          if (!regFIO.test(model.name)) {
            return ['Проверьте правильность ввода имени']
          }
          return []
        }
      },
      {
        type: 'bootstrap',
        inputType: 'text',
        model: 'midname',
        styleClasses: 'wm-100',
        label: 'Отчество',
        placeholder: 'Отчество'
      },
      {
        type: 'bootstrap',
        inputType: 'text',
        model: 'mail',
        styleClasses: 'wm-100',
        disabled: true,
        label: 'Почта',
        placeholder: 'student-leti@etu.ru'
      },
      {
        type: 'bootstrapMasked',
        inputType: 'text',
        label: 'Телефон',
        model: 'phone',
        mask: phoneMask,
        styleClasses: 'wm-50 pr-md-2',
        placeholder: 'Телефон'
      },
      {
        type: 'datepicker',
        label: 'Дата рождения',
        model: 'birthday',
        styleClasses: 'wm-50'
      },
      {
        type: 'bootstrap',
        inputType: 'password',
        model: 'password',
        required: true,
        styleClasses: 'wm-100',
        label: 'Пароль',
        placeholder: 'Пароль',
        onChanged: this.updateValidateSchema,
        validator(value, field, model) {
          if (model.password.length < minLengthPassword) {
            return [`Пароль содержит меньше ${minLengthPassword} символов`]
          }
          return []
        }
      },
      {
        type: 'bootstrap',
        inputType: 'password',
        required: true,
        model: 'samePasswordForConfirm',
        styleClasses: 'wm-100',
        label: 'Подтверждение пароля',
        placeholder: 'Повторите пароль',
        onChanged: this.updateValidateSchema,
        validator(value, field, model) {
          if (model.samePasswordForConfirm.length < minLengthPassword) {
            return [`Пароль содержит меньше ${minLengthPassword} символов`]
          }
          if (model.samePasswordForConfirm !== model.password) {
            return ['Пароли не совпадают']
          }
          return []
        }
      }
    ]
  }

  private formOptions = {
    validateAfterLoad: true,
    validateAfterChanged: true,
    validateAsync: true
  }

  private async created() {
    if (this.code) {
      try {
        const userData = await UserAPI.checkActivationCode(this.code as string)
        this.userInfo = {
          userId: userData.data.id,
          surname: userData.data.surname,
          name: userData.data.name,
          midname: userData.data.midname,
          mail: userData.data.email,
          activationCode: this.code as string,
          phone: userData.data.phone,
          birthday: userData.data.birthday,
          agreement: '',
          password: '',
          samePasswordForConfirm: ''
        }
      } catch (err) {
        this.$router.replace('/auth')
      }
    } else {
      this.$router.replace('/auth')
    }
  }

  get isAgree() {
    return this.userInfo.agreement
  }

  private async registerUser() {
    if (this.userInfo.password !== this.userInfo.samePasswordForConfirm) {
      this.pushToast({
        error: true,
        title: 'Ошибка',
        message: 'Пароли не совпадают! Проверьте правильность ввода!',
        time: 5
      })
    } else {
      try {
        if (this.userInfo.birthday) {
          this.userInfo.birthday = ContentAdapter.changeNewsDateFormatToServer(
            this.userInfo.birthday
          )
        }
        await UserAPI.registration(this.userInfo)
        this.$router.push('/auth')
        this.pushToast({
          title: 'Успешно',
          message: 'Регистрация выполнена успешно',
          time: 5
        })
      } catch {
        this.pushToast({
          error: true,
          title: 'Ошибка',
          message: 'Во время регистрации произошла ошибка',
          time: 5
        })
      }
    }
  }
}
